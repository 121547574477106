<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { authHeader } from "../../../helpers/auth-header";

import FilterModal from "@/components/modals/filter-modal.vue";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";

import ListingTable from "@/components/listings/listing-table";

export default {
	components: {
		Layout,
		PageHeader,
		FilterModal,
		ConfirmationModal,
		MessageModal,
		ListingTable,
		InputGroup,
		SelectGroup,
		// StatusGroup
	},
	data() {
		return {
			title: "Service Professionals",
			items: [
				{
					text: "Service Professionals",
				},
			],
			filter: {
				name: '',
				phone: '',
				email: '',
				status: 'all',
				services: 'all',
				service_providers: 'all',
				created_date_from: '',
				created_date_to: '',
			},
			fields: [
				{ key: "name", sortable: true, label: "Full name" },
				{ key: "phone", sortable: true, label: "Contact number" },
				{ key: "email", sortable: true, label: "Email address" },
				{ key: "application_status", label: "Status" },
				{ key: "action" },
			],
			statusOptions: [
                { name: 'All', value: 'all' },
                { name: 'Approved', value: 'approved' },
                { name: 'Pending', value: 'pending' },
				{ name: 'Rejected', value: 'rejected' },
            ],
			serviceOptions: [],
			serviceProviderOptions: [],
			addUrl: `${window.location.origin}/service_professional/form/add`,
			isModalVisible: false,
		};
	},
	async mounted() {
		this.retrieveServices().then((services) => {
			this.serviceOptions = services.map((service) => ({
				name: service.name,
				value: service.id,
			}));

			// set All as first one
			this.serviceOptions.unshift({
				name: 'All',
				value: 'all',
			});
		});

		this.retrieveServiceProviders().then((service_providers) => {
			this.serviceProviderOptions = service_providers.map((service_provider) => ({
				name: service_provider.name,
				value: service_provider.id,
			}));

			// set All as first one
			this.serviceProviderOptions.unshift({
				name: 'All',
				value: 'all',
			});
		});
	},
	methods: {
		async retrieveServices() {
			let services = [];
			let filter = {
				sort_order: 'asc',
				sort: 'name'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    services = result['data'].services;
                }
            } catch (e) {
                console.error(e);
            }

            return services;
		},
		async retrieveServiceProviders() {
			let service_providers = [];
			let filter = {
				sort_order: 'asc',
				sort: 'name'
			};

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service_provider`, {
                    headers: authHeader(),
					params: filter
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    service_providers = result['data'].service_providers;
                }
            } catch (e) {
                console.error(e);
            }

            return service_providers;
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		deleteRecord(id) {
			const deleteCallback = async () => {
				await axios.delete(`${process.env.VUE_APP_APIURL}/tutor/${id}`, {
					headers: authHeader(),
				});

				this.filterTable(this.filter);
				this.$refs.confirmationModal.closeModal();
				this.$refs.messageModal.showModal("The record has been deleted");
			};
			this.$refs.confirmationModal.showModal(`Confirm delete service professional (ID: ${id})?`, deleteCallback);
		},

		setMemberView(filter) {
			this.filter = Object.assign(this.filter, filter);
		},

		filterTable(filters) {
			const filter = JSON.parse(JSON.stringify(filters));
			this.$refs.listingTable.filterTable(filter);
		},
		resetTable() {
			this.filter = {
				name: '',
				phone: '',
				email: '',
				status: 'all',
				services: 'all',
				service_providers: 'all',
				created_date_from: '',
				created_date_to: '',
			};
			this.$refs.listingTable.filterTable(this.filter);
		},
		showFilterModal() {
			this.$refs.filterModal.showModal();
		},
		hideModal() {
			this.isModalVisible = false;
		},
		handleSubmit() {
			this.filterTable(this.filter);
			this.$refs.filterModal.closeModal();
		},
		async exportTutor() {
            try {
				let filter = this.filter;

				const response = await axios.get(`${process.env.VUE_APP_APIURL}/export-tutor`, {
				headers: authHeader(),
					params: filter,
				});

				const result = response.data;
				if (result['code'] === 'success') {
					console.log('result', result);

					const excelBase64 = result['excel_base64'];

					const byteCharacters = atob(excelBase64);
					const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
					const byteArray = new Uint8Array(byteNumbers);
					const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

					const url = URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.download = 'service_professionals.xlsx';
					link.click();

					URL.revokeObjectURL(url);
				} else {
					console.error('Export failed:', result.message || 'Unknown error');
					alert('Export failed:', result.message || 'Unknown error');
				}
            } catch (e) {
                console.error(e);
            }

            return;
        },
	},
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />

		<FilterModal ref="filterModal" :isVisible="isModalVisible" @close="hideModal">
			<form @submit.prevent="handleSubmit">
				<div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.name" type="text" id="name" displayName="Full name" />
                        </div>
                    </div>
					<div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.phone" type="text" id="phone" displayName="Contact number" />
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.email" type="text" id="email" displayName="Email address" />
                        </div>
                    </div>

					<div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.services" id="services" displayName="Service" :options="serviceOptions" />
                        </div>
                    </div>

					<div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.service_providers" id="service_providers" displayName="Service Professional" :options="serviceProviderOptions" />
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.status" id="status" displayName="Status" :options="statusOptions" />
                        </div>
                    </div>

					<div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.created_date_from" type="date" id="created_date_from" displayName="Created From" />
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.created_date_to" type="date" id="created_date_to" displayName="Created To" />
                        </div>
                    </div>
				</div>

				<div class="modal-footer">
                    <button type="button" class="clear__btn" @click="resetTable">Clear</button>
                    <button type="submit" class="save__btn">Filter</button>
                </div>
			</form>
		</FilterModal>

		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body pt-0" style="position: relative;">
						<div class="filter_buttons">
							<button class="filter_button" @click="exportTutor">
								<i class="mdi mdi-download mr-2 font-size-17"></i>Export
							</button>
							<button class="filter_button" @click="showFilterModal">
								<i class="mdi mdi-filter-variant mr-2 font-size-17"></i>Filter
							</button>
							<a class="add_new_button" :href="addUrl">
                                <i class="mdi mdi-plus mr-2 font-size-17"></i> Add New Service Professional
                            </a>
						</div>

						<ListingTable ref="listingTable" :fields="fields" path="tutor" listName="tutors">
							<template v-slot:cell(application_status)="row">
								<div :class="{
										'status-red': `${row.value}` === 'rejected',
										'status-green': `${row.value}` === 'approved',
										'status-blue': `${row.value}` === 'pending',
									}">
									{{ capitalizeFirstLetter(row.value) }}
								</div>
							</template>

							<template v-slot:cell(action)="{ item }">
								<router-link class="mr-2 text-primary"
									:to="{ name: 'Tutor View', params: { id: item.id } }"><i
										class="mdi mdi-eye font-size-18" v-b-tooltip.hover title="View"></i>
								</router-link>

								<router-link class="mr-2 text-primary"
									:to="{ name: 'Tutor Form', params: { id: item.id } }"><i
										class="mdi mdi-pencil font-size-18" v-b-tooltip.hover
										title="Edit"></i>
								</router-link>
								<a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
									@click="deleteRecord(item.id)">
									<i class="mdi mdi-trash-can font-size-18"></i>
								</a>
							</template>
						</ListingTable>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<style scoped>
.modal-footer {
    padding-top: 24px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 48px;
    justify-content: space-between;
    border-top: 1px solid rgba(205, 205, 205, 0.5);

    & .clear__btn {
        border: 0;
        background-color: transparent;
        font-family: Nunito;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #505D69;
        text-decoration: underline;
    }

    & .save__btn {
        border: 1px solid #004AAD;
        border-radius: 6px;
        background: #004AAD;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
    }
}
</style>